import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import getPage from './endpoint.get-page';

import Home from './pages/home';
import About from './pages/about';
import News from './pages/news';
import Lore from './pages/lore';
import Genniken from './pages/genniken';
import Gytarrh from './pages/gytarrh';
import Massadoon from './pages/massadoon';
import Phouss from './pages/phouss';
import Bolgh from './pages/bolgh';
import Malechia from './pages/malechia';
import Codex from './pages/glossary';
import Slimland from './pages/slimland';
import Posts from './pages/individualPost';

import './App.css';

const Error = () => {
  return (
    <div><a href="/">WHOOPS BANANAS</a></div>
  );
};

function App(props) {
  const [posts, setPosts] = useState({});
  const [page, setPage] = useState(-1);


  useEffect(() => {
  if (page !== props.page) {
    setPage(props.page);
    setPosts({});
  } else {
    if((Object.keys(posts)).length !== props.postList.length) {
      props.postList.forEach((postNum) => {
        if(!(postNum in posts)) {
          // Has never been requested
          const stale = {
            ...posts,
            [postNum]: null,
          };
          setPosts(stale);

          const fetchPage = async () => {
            const post = await getPage(postNum);
            posts[postNum] = post;
            const update = {
              ...posts,
              [postNum]: post,
            }
            setPosts(update);
          }
          fetchPage();
        }
            });
    }
  }
  }, [page, props.page, props.postList, posts])

  return (
    <Router>
      <Switch>
        <Route path='/' exact >
          <Home posts={posts} page={page} totalPages={props.totalPages} />
        </Route>
        <Route path='/about' component={About} />
        <Route path='/news' component={News} />
        <Route path='/lore/genniken' component={Genniken} />
        <Route path='/lore/gytarrh' component={Gytarrh} />
        <Route path='/lore/massadoon' component={Massadoon} />
        <Route path='/lore/phouss' component={Phouss} />
        <Route path='/lore/bolgh' component={Bolgh} />
        <Route path='/lore/malechia' component={Malechia} />
        <Route path='/lore/codex-goblinarium' component={Codex} />
        <Route path='/lore' component={Lore} exact />
        <Route path='/slimland' component={Slimland} />

        <Route path='/posts/:id' component={Posts} / >

        <Route component={Error} />
      </Switch>
    </Router>
  );
}
//<Route path='/reviews' component={Reviews} /> 
export default App;
