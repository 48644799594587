import React from 'react';
//import styled from 'styled-components';

import Page from '../templates/lorepage';

import Slim from '../components/slim';


function PageBase() {
  return (
    <Page>
      <h1>Gytarrh</h1>
      <h2>Second House of Mepho</h2>
      <p>Motto: “Only The Brazen” (They’re the Brazen)</p>
      <p>Goblins' goblins. Common in every sense of the word. the Gytarrh exemplify goblinhood: tenacious, clever, difficult to exterminate, worse to educate, skittish, greedy and fickle. And yet, impressively adaptable. Gytarrh can be found in every dark corner of every place. There's probably a few in your pantry right now, scranning a few cans of beans into their beloved, accursed bags.</p>
      <p>Originally known as the Mephos, the Gytarrh are one of the oldest and most respected goblin houses. Second in influence only to the cunning Genniken goblins, and far outnumbering them, the Gytarrh are the majority of the population of the Mephostan empire. An observant reader will note the empire shares a name with the clan's original name - the modern city of Mepho is the ancestral homeland of the Gytarrh, and they were the original founders of the city.</p>
      <p>Gytarrhans tend towards modesty, often covering all but their small, black eyes when appearing in public. These coverings are also practical - as one of the more frequently hirsute goblin varieties, their often thick, curly hair can be a danger during their fiery escapades. It can also be a hazard whilst gliding about town on their daily errands. With regards to their hair, the closest thing to an identifying feature their morph has is the typical ability to grow a full beard and moustache, as most goblins struggle to get a full coverage, and most non-Gytarrh tend towards only being able to grow one or the other. Their skin is always green, though some are so dark or pale it can be difficult to see. They are never blue, red, or metallic. They generally do not (but can occasionally) grow horns, fangs, scales, tails, or wings. Their ears are considerably rounder than is typical amongst most other goblins. Their features remain in fixed proportion after they emerge as a hob, and they do not continue growing after full adulthood. Their height is average to above average amongst other morphs. It is often very difficult to decisively identify a Gytarrh, and it is not unheard of for them to pass as other morphs for whatever reason.</p>
      <p>A final, curious note on the Gytarrh: they are well known for their signature satchels. These dingy, worn bags contain everything from groceries and tools, to salvage, garbage, personal treasure, even children. Known outside the empire as “Abysmal Bags”, it is unwise to explore one unaided, first for the bags frequently dangerous or disgusting content, and second for the wrath of the owner.</p>
      <Slim x={-100} y={-200}></Slim>
    </Page>
  );
}

export default PageBase;