import React from 'react';
//import styled from 'styled-components';

import Page from '../templates/page';

import Slim from '../components/slim';


function News() {
  return (
    <Page>
        EXPLORE GOBLIN WEEK 2021
        <Slim></Slim>
        <iframe allowFullScreen id="wallsio-iframe" src="https://my.walls.io/h5pj3?nobackground=1&amp;show_header=0" style={{"border":0,"height":"800px","width":"100%"}} loading="lazy" title="My social wall"></iframe>
    </Page>
  );
}

export default News;