import React from 'react';
//import styled from 'styled-components';

import Page from '../templates/page';

import Slim from '../components/slim';

const windowDimensions = document.querySelector('html').getBoundingClientRect()

const slimCount = 100;

const closestPerfetSquare = Math.floor(Math.sqrt(slimCount));
let cols = 0;
let rows = 0;
for(let i = closestPerfetSquare; i > 0; i--) {
    if(slimCount % i === 0) {
        if(windowDimensions.width >= windowDimensions.height) {
            rows = i;
            cols = slimCount / i;
        } else {
            cols = i;
            rows = slimCount / i;
        }
        break;
    }
}

let movementX = windowDimensions.width / cols;
let movementY = windowDimensions.bottom / rows;

function PageBase() {

    const windowDimensions = document.querySelector('html').getBoundingClientRect()
    movementX = windowDimensions.width / cols;
    movementY = windowDimensions.bottom / rows;
    let slims = [];
    if(windowDimensions.bottom > 0) {
        slims = (new Array(slimCount)).fill('').map((v, i) => <Slim key={"slim" + i} x={movementX * Math.floor(i/cols) + (Math.random() * 12 - 6)} y={movementY * (i % cols) + (Math.random() * 12 - 6)}></Slim>);
    }
  return (
    <Page>
      <h1>WELCOME TO SLIMLAND FIND THE SPECIAL SLIM AND GET NOTHING</h1>
      {slims}

        <iframe title="the tunes" width="560" height="315" src="https://www.youtube.com/embed/yZEKlp-H6FE?start=36&autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <Slim x={Math.random() * 10000 % windowDimensions.width} y={Math.random() * 10000 % windowDimensions.height} special={() => alert("you win")}></Slim>
    </Page>
  );
}

export default PageBase;