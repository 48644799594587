import React from 'react';
import styled from 'styled-components';
import gearsbackground from '../gearsbackground.jpeg';


const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 80px;

  @media (max-width: 800px) {
   font-size: 40px; 
  }

`;
const Body = styled.div`
    margin: 0px -40px;
    text-align: right;

    font-family: "baangkarr", impact,arial,sans;
    
    background-image:url(${gearsbackground});

    .heading-wrapper {
      background-color: transparent;
    }

    a.heading, a.heading:link, a.heading:visited {
      color: green;
      content: "":
      background-color: transparent;
    }   

    a.heading::before {
      content: "";
    }

    a::before {
      content: "🔗 ";
    }

    a {
      flex 1 1 33%
      color: white;
      filter: drop-shadow(10px 10px 4px #ffffff);
      text-shadow: black 1px 1px;
    }
    a:link {
      color:white;
    }

    a:visited {
      color:white;
    }

 
    a:hover {
      color:yellow;
    }

    span{
      color: white
    }

    p {
      font-size: 150px;
      margin-top: 0px;
      margin-bottom: 10px;
    }
`;

const WrapperWrapper = styled.p`
  position: relative;
`;

const SubheadSpan = styled.span`
  position: absolute;
  bottom: 0;
  right: 365px;
  font-size: 25px;
`;

const Subhead = () => {
  const phrases = [
    "Your home for Goblin biz",
    "YeaaaaaaGhGrhH!",
    "Not remotely attempting coherency!",
    "Goblin News, hot off the presses!",
    "Like a good website about Goblins, but not!", /* Nothing longer than this */
    "Goblins! Everything about em!",
    "Goblins! Goblins Goblins! Goblins?",
  ]
  const pos = Math.floor((Math.random() * 1000) % phrases.length);
  return <SubheadSpan>{phrases[pos]}</SubheadSpan>
}

const Header = (props) => {


  return (
      <Body >
        <WrapperWrapper className="heading-wrapper"><a className="heading" href="/">goblinarium.net</a><Subhead>Your home for goblin biz</Subhead></WrapperWrapper>  
         <Wrapper>
          <a className='about' href='#/about'>about</a>
          <span>|</span>
          <a className='news' href='#/news'>goblin week</a>
          <span>|</span>
          <a className='lore' href='#/lore'>lore</a>
        </Wrapper>
        {props.children}
      </Body>

  );
}

export {
  Header
};

