import React from 'react';
import styled from 'styled-components';

import Page from '../templates/lorepage';

import Slim from '../components/slim';



const Floater = styled.img`
    float: ${props => props.float || "left"};
`;

function PageBase() {
  return (
   
   
   <Page>
      <h1>Genniken</h1>
      <h2>The First House of Mepho</h2>
      <p><b>Mottos: “Moon Lords”, “Ever Taller”</b></p>
      <p><Floater src="/genniken-2.jpg" width="250"></Floater>Watch out for these little "devils"! Often mistaken for actual denizens of hell, their crappy attitudes don't help the situation much. The Genniken's primary weapon is their scheming cleverness, which is how they wormed their way through the Elites of the Mephostan court to become the First (ruling) House of Mephos! Their second weapon is shark teeth.</p>
      <p>The signature trait of a Genniken is their lengthy extremeties. Their noses, ears, tails, feet, and fingers grow continuously throughout their life, which can lead to geezerly Genniken of truly comic disproportion. Most Genniken are also born with wings and tails, which helps along the imp comparisons, as does their typically short stature. Genniken are amongst the shortest of all goblins. Getting back to their teeth, like sharks they are continuously growing new rows, which keeps them in biting shape at all times. Their eyes are typically metalic, gold, silver, or grey, and their skin is typically red, blue, or grey. These are unsurprisingly their house colors.</p>
      <p>Genniken are, even by goblin standards, exceptionally vain. As such, their primary love is distinction. Like many goblins, they are prone to hoarding wealth and valuable items, but unlike most they do this not as an end upon itself, but as a means for acquiring more power. This too is a means to an end, and any self respecting Genniken will use the full force of their worldly power to obtain the latest in fashion and finery. And if you think the fashoin world here moves fast, tastes in the Goblinarium must be monitored hour to hour, if not more frequently. Genniken frequently employ fashionmancers to scry the mystical planes for this purpose.</p>
      <p><Floater src="/genniken.png" float="right"></Floater>One particular item that is always sought after by Genniken regardless of the tastes of the hour is Moonsilver. This rare mineral is exceptionally beautiful, but is typically only used for sorcerous Augery. Genniken, however, believe they are originally from the moon, and that somehow amassing enough of the moonsilver will allow them to return. Who knows maybe it will. Anyway, the Genniken are actually from the nominal craigy desert cliffs of Genn to the whest of Mepho.</p>
      <p>While privately most consider Genniken rule over Mepho to have been fairly shoddy work for the last few generations, one cannot overlook their key innovation: flight. Far from vestigal, the Genniken wings are capable of short bouts of flight, and extended gliding. Genniken take particular pleasure in lording this power over other goblins who are confined to, in the best of cases, gliders, but more frequently simply throwing themselves off of things they don't want to be on anymore. Goblins are hardy things. All this tossing about of goblins has greatly affected their architecture, particularly in areas under Mephostan control, and nowhere can this be more clearly seen than the towering, hive like spires of Mepho.</p>
      <p>One area in which Genniken truly do meaningfully distinguish themselves from other goblins is their uniquely un-gobbish penchants for planning and teamwork. This was certainly played a critical role in their ascendancy to and stewardship of the first house. Their Gytarrhan countrymen, the original natives of Mepho, do not posess these traits and tend to act as most goblins do: on a whim. As such, they like nothing better than imagining what it must be like to live as a prince; Genniken are delighted to collaborate to show them. Were it not for their near-permanent, universal bachelordom (their standards are impossibly high), they would likely have conquered the entirety of the Goblinarium. Instead, they are largely satisfied to conspire and scheme amongst themselves to keep their own power, rigging elections, redrawing maps, and occasionally banishing the odd neighbor or neighborhood. It is no accident that the vast majority of Mephistan dukedoms and thanehoods are headed by the sons and daughters of Genn.</p>
      <p>Spiritually, the Genniken diverge from their countrymen once again, in that they typically do not follow their own state religion to the gods of might and fire. Insead, they follow the gods of change and chaos. With their hands in every available jar, the Genniken know that instability is also opportunity. Favoring the ever shifting, but rarely outright destructive, forces of wind and air, the Genniken faithful do draw the line before total chaos, however. Unlike all other goblins, Genniken despise fire and avoid it whenever possible. This makes them particularly unsuited to the field of battle, where their own Bolgh bombardiers lob fierce granados into the ranks of the natural fire-mage clans of their hated Rhambynge and Lla’amosa rivals. While top officers from their house are always present in the field, the Mephistan legions are notably and noticeably thin of Genniken regulars. This socially-reprimanded behavior extends to the many fire rituals raised throughout the year in service to the national cult to the fire god Magnus, to which Genniken participation is generally lip service. They (out of necessity) tolerate their countrymen’s continual fire sacrifice, and tend to keep to themselves outside of the most critical official functions of the coordinated spectacle. By the time the numerous, inevitable fireworks are lit, every proper Genniken is in bed with her ears stuffed with wax. </p>
      <p>This is not to say that the House of Genniken is without mirth, or pomp and circumstance. The yearly “Triumph” to their most recent “victory” (whatever that might be this hour) aligns with the festival of the winter solstice, “Quadra”, and the most holy days for most of the cults throughout the empire. Ever vying (but never achieving) for supremacy of their orchestras against their Massadoon brothers, this is a day of horns and music. The Genniken will reveal the works they have created and commissioned throughout the year in decoration of their homes and bodies, hosting lavish parties and balls that always descend into fevered opulence by the end of the evening. It is during these times that the royalty and riff-raff of the house come together to consume any manner of corrosive inebriant; This annual loosening of moral quandaries via substance-consumption allows for rare family moments and the return of prodigal sons. As Genniken on down on their luck are typically effective underworld agents of all varieties, this is also an excellent time for under-the-table dealing, and the forging of less than official agreements and alliances.</p>
      <p>Expanding upon the effect of falling upon hard times for a Genniken, they remain true to their natures. Crime is a standard of the “fortune upcoming” Genniken, turning towards fraud, prostitution, and piracy, but (excepting the pirates), avoiding outright theft or murder whenever possible. Genniken gangsters can be both powerful allies, and frightening neighbors, and their Pirate kings are the subject of many a legend and fairy-tale. One particularly persistent story concerns the Pirate-king “greenbeard”, whose appearance, while terrible and destructive, is indicative of good fortune in a coming enterprise, particularly war, where the Pirate will appear and grant aid in exchange for looting rights.</p>
      <Slim x={-100} y={-200}></Slim>
    </Page>
  );
}

export default PageBase;