import React from 'react';
//import styled from 'styled-components';

import Page from '../templates/lorepage';

import Slim from '../components/slim';


function PageBase() {
  return (
    <Page>
      <h1>Codex Goblinarium</h1>
      <p><b>Genniken: </b>1st House of Mephostus. Vain Emperors. Descendants of the nation of Genn. Amongst themselves, "Moon Lords". Winged morph. Disproportionate ears, noses, fingers, teeth, and feet. Shark like rows of teeth. Three aesthetic subgroups, red, blue, grey.</p>
      <p><b>Glossary: </b>An uncommon Polish dessert.</p>
      <p><b>Goblinarium: </b>A world. The world. The place in which all hordefolk live. The “Planet”, though Goblins are neither aware of the concept of a world in this way, nor is the Goblinarium an astronmical body drifting through space. Within its firmament, the Goblinarium does contain a planet on which all life exists.</p>
      <p><b>Goblins: </b>A species of Hordefolk. They are generally horrible, squirrelly little green dudes who sew chaos and mischief wherever they can be found, which is everywhere. As a rule, all goblins are flawed by selfull vice and sin. In short, goblins are not “good”, which is not to say that they are inherently evil, either. They are cosmopolitan to the Goblinarium, having spread far and wide using their cunning, stealth, and crushing rate of reproduction. Goblins are generally vain, conniving, and skittish, though no single set of descriptions can cover the massive possible variations that occur among them. This said, they possess remarkable resilience and intelligence for all their wildness, having formed complex societies and developed advanced technologies. Once established, Goblins are essentially impossible to remove.</p>
      <p><b>Gytarrh: </b>Formerly "Mepho". 2nd House of Mephostus. Original founders of the city of Mepho. Commoners, soldiers. Mellowest of all goblin morphs. Generalists. Numerous. Hairiest of goblins, slightly rounder ears. Green. Difficult to identify with certainty.</p>
      <p><b>Hob: </b>The final phase of Goblin maturity, in which they acquire the traits of a morph. Hob morphs vary wildly, and even in size run from as small as a half meter, to nearly two in the case of the Massadoon, the largest known morph.</p>
      <p><b>Lla’amosa: </b>2nd Nation of Malken. Dangerous Scientists, bringers of cataclysm. Wizards. Mastery over fire. Not mastery over safety. Blue. Fiery eyes. Lumpy noses.</p>
      <p><b>The Lost: </b>Most powerful of the Primals. Solitary hunters. Extremely Dangerous. Scaled morph. Fanged morph. Tailed morph. Powerful tail. Occasionally Horned morph. Occasionally winged morph. Crocodiles. Monsters. Dragons.</p>
      <p><b>Massadoon: </b>3rd House of Mephostus. Builders, brawlers. Musicians. Horned morph. Occasionally fanged morph. Vigorous trumpeters.</p>
      <p><b>Malechia: </b>6th House of Mephostus. Teachers, Heroes, Farmers. Commoners. Naturalists. Hippies and Punks. Widely oppressed. Fanged morph. Shroom splicers. Recently annexed from Malken and not super pumped about it.</p>
      <p><b>Mephostus, The Empire of: </b>The dominant power in the charted Goblinarium. Mephostus formed when the Genniken took political control of the nation of Mepho, (their tribe now called Gytarrh). Mephostus expands via conquest, and their Legionnaires are legendary. The absorbed nations that make up Mephostus are Genn, Gytarrh, Massadoon, Phous, Bolgh, and Malechia. Their primary rival is the Malken Concordant, a loose confederation of states to their North and East. The far west of the nation is wild and unexplored. Most Mephostan populations reside in enormous hive-cities, expanding vertically as much as over land.</p>
      <p><b>Malken: </b>The primary rival to Mephostus for general control of the charted Goblinarium. Malken, while unified and with open borders and trade, maintain their individual nations fiercely. These nations came together in resistance to the rising power and aggression of Mephostus in the west. Unlike Mephostus, Malken cities are more conventionally laid out, though this is likely partially because their technology and construction techniques are inferior. The Nations of Malken are Rhambl’j, Lla’amosa, Beldrin, Hamgut, Croid, and T'tapow. While the Rham are the unquestionable leaders of the coalition, they are a turtle kingdom and much about them remains a mystery. Their neighbor, the Lla’amosa, who share their island continent in the far northern sea, tend to conduct the management of the concordant on their behalf. The lesser houses are along the northern and eastern coasts of the continent.</p>
      <p><b>Morphs: </b>Goblins have a three stage lifecycle. All begin as parasitic mushrooms that compete aggressively for organic resources, frequently consuming one another and all nearby flora and fauna in their frenzied growth. Once of sufficient size and development, the “eggcap” (the fruiting body of the growing fungal mass) will unfurl into its second stage, a larval chitterling. Chitterlings are incredibly destructive and continue to absorb organic matter, particularly the spores of other nearby Hordefolk, until the conditions are right for their metamorphosis. At this stage, all chitterlings are essentially identical. They will burrow and then cocoon themselves, eventually emerging into their “Hob”, and gaining the traits of their given Morph. The resulting morph is primarily determined by the spores absorbed during the eggcap and chitterling stages, though may factors influence this emergence.</p>
      <p><b>Phouss: </b>4th House of Mephostus. Beauties. Lunatics. Long, thin, pale. “Elves”, amongst themselves, though they are nothing of the sort. Tailed morph. Antennae in place of ears. Empaths.</p>
      <p><b>Primals: </b>Primal Goblins appear when spores are left unchecked in an area and allowed to grow wild. In the absence of concentrations of Hob spores, one of the primal morphs will appear. There are three true Morphs of primal goblin; The Lost, The Fallow, and Chitterlords. Primals can be found anywhere near other goblins where things have been left neglected, frequently appearing in ruins, dungeons, on the outskirts of settlements, and of course, most commonly, in the wild. They are considered by most to be vermin to be exterminated, though all have their own sorts of culture, intelligence, and individual personality.</p>
      <p><b>Rhambl'j: </b>1st House of Malken. Deeply mysterious. “Copper goblins”, Horned morph, though only as “crowns”. Similar build to Phouss. Don't call them, they'll call you.</p>
      <p><b>“Sirincusi”, The Unbroken: </b>The only non-primal, unaligned Free Folk. Considered barbarians, vagrants. The only shelled morph. This label is often misapplied to all free folk nations, as well as primals</p>
      <p><b>T’tapow: </b>5th Nation of Malken. Fanged Morph. Beaked morph. Real into birds. Some have feathers. Occasional winged morph, which is a big yes in their fashion. Love hats. Love building ziggarauts.</p>
      <p><b>Beldrin: </b>3rd Nation of Malken. Ugly. So ugly. “motto” : “fuck no.”. Jacked. Warrior culture, organized into city states at constant war. Horned morph, natural helmets. Grow thick semi-prehensile tendrils amongst their hair. Generally, nasty fellows.</p>
      <p><b>Bolgh: </b>5th House of Mephostus. Mystics, Alchemists, genadiers. No fear of death. Bit creepy. Like black. Love secrets.</p>
      <p><b>Chitterlings: </b>The second phase of the Goblin lifecycle. These are considered Goblin children, but their instincts for survival, including the construction of weapons and the digging of underground hives, makes them extremely dangerous if allowed to run wild. Chitterlings are typically quite small, generally under a half a meter high.</p>
      <p><b>Chitterlords: </b>Vermin Primals. Bosses of underground chitterling colonies. Typically proper dickheads. Sometimes surprisingly effective detectives.</p>
      <p><b>Croid: </b>6th Nation of Malken. “Thought thieves”. Will eat your brain. Shrimpy, but numerous. Tailed morph.</p>
      <p><b>Eggcap: </b>The first phase of the Goblin lifecycle. They are essentially immobile mushrooms in this phase.</p>
      <p><b>The Fallow: </b>Mysterious, fey Primals. Forest wards and herders of chitterlings. Eccentric decorators.</p>
      <p><b>Free Folk: </b>Many independent tribes exist unaligned with either nation. Entire proto-nations of unknown “barbarian” free folk live in the western wastes, as well as throughout conquered lands. The free folk are often of the “Unbroken”, aka “Sirincusi” morph of Goblin, though their numbers include all the goblin “Primals”, The Lost, The Fallow, and Chitterlords. Many conflate the Unbroken morph label to all free folk and primals, leading to confusion.</p>
      <p><b>Hamgut: </b>4th Nation of Malken. Frogmen, nuff said. Largest, fanlike ears among all goblins. Occasionally medusas. Very into body modification.</p>
      <p><b></b></p>
      <p><b></b></p>
      <p><b></b></p>
      <p><b></b></p>
      <p><b></b></p>
      <p><b></b></p>
      <p><b></b></p>
      <p><b></b></p>
      <p><b></b></p>
      <p><b></b></p>
      <p><b></b></p>
      <Slim x={-100} y={-200}></Slim>
    </Page>
  );
}

export default PageBase;