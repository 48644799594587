import React from 'react';
//import styled from 'styled-components';

import Page from '../templates/page';

import Slim from '../components/slim';


function About() {
  return (
    <Page>
      <h1>THE GOBLINARIUM</h1>
      <p>Concisely, the world.</p>
      <p>Less vaguely, the goblinarium is two things. First, and foremost, it is a love of the nasty little green men who live under rocks, in the deep woods, and in closets. It is secondly a setting, a home for goblins of all shapes and sizes. Life in a Goblinarium is hard, brutal even, and short. Or does it go on forever? But you knew that.</p>
      <p>There are, of course, goblins of the mind as well as the flesh and blood fey fellows that undarn your socks and eat all your cheese. This is one of my goblins. Or perhaps I am one of its. It is? It's.</p>
      <p>Don't try so hard. It might be rough being a goblin, but that doesn't mean it can't be fun.</p>
      <p>I intend to fill this with goblins. Art. Reviews. Anything I can find. A hub for your daily dose of goblin news. And when there is no news, I'll make it up, just for you.</p>
      <p>Do stop on by.</p>
      <p>and welcome</p>
      <p>To</p>
      <p>THE GOBLINARIUM</p>
      <p><i>~ with love, Max</i></p>
        Goblinarium website by luke and max
        <Slim></Slim>
        <Slim x={500} y={200}></Slim>
        <Slim x={1100} y={200}></Slim>
    </Page>
  );
}

export default About;