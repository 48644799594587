import styled from 'styled-components';

//import logo from '../../public/forest.jpg';

import {Footer} from '../components/footer'
import {Header} from '../components/header'
import Loremenu from '../components/loremenu';


const PageTop = styled.div`
  font-size: 1.5em;
  text-align: center;
  color: green;
  background-color: black;
  background-image: url(/forest.jpg);
  background-size: cover;
  padding: 0 40px 0 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Body = styled.div`
    flex-grow: 1;
    flex-shrink 0;
    flex-basis: auto;
`;

const FixedHeader = styled.header`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
`;

const FixedFooter = styled.footer`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
`;

const Page = (props) => {
    return (
        <PageTop id="pageroot">
            <FixedHeader>
            <Header></Header>
            </FixedHeader>
             
            <Body id="goblinarium-body">
                <Loremenu />
                {props.children}
            </Body>
            
            <FixedFooter>
               <Footer></Footer>
            </FixedFooter>
        </PageTop>
    );
}

export default Page;