import styled from 'styled-components';
import { CommentCount } from 'disqus-react';

//import logo from '../logo.svg';
import '../App.css';

//import Menu from '../components/menu';
import Page from '../templates/page';

import Slim from '../components/slim';

const PostContainer = styled.div`
  text-align: center;
`;

const Post = styled.div`
  color: white;
  background-color: #16161d;
`;

function App(props) {
  const posts = props.posts || {};
  return (
    <Page>
      <div className="App">
        <p>Welcome to the Goblinarium</p>
        <p>This website is dedicated to delivering all the best goblin content there is. Check back for original goblin stories and art, goblin reviews, and other assorted goblin nonsesne!</p>
        <p>It is also goblin week 2021 (1/24-1/30) - our news page has temporarily been converted to a feed of all the amazing content, check it out!</p>
        <h2>latest update: </h2>
        {(Object.keys(posts)).map((v) => {
          return (<Post key={v}>
            <div dangerouslySetInnerHTML={{ __html: posts[v] }} />
            <div><a href={`#/posts/${v}`}><span>Permalink - </span>
              <CommentCount
                shortname='goblinarium-net'
                config={
                  {
                    url: `https://goblinarium.net/#/posts/${v}`,
                    identifier: v,
                    title: `Goblinarium Post ${v}`,
                  }
                }
              >
                {/* Placeholder Text */}
                Comments
              </CommentCount>
            </a></div>
          </Post>)
        })}
      </div>
      <p>
        {(props.page !== 0) && <a href={"?page=" + (props.page - 1)}>{"<"}---- Less? Newer?</a>}
        {(props.totalPages - 1 !== props.page) && <a href={"?page=" + (props.page + 1)}>More? Older ----></a>}
      </p>
      <Slim></Slim>

      <PostContainer>
        <iframe className="instagram-media instagram-media-rendered" id="instagram-embed-0" src="https://www.instagram.com/p/CKSJ_1KjwiH/embed/captioned/?cr=1&amp;v=13&amp;wp=1034&amp;rd=https%3A%2F%2Ffiddle.jshell.net&amp;rp=%2FMaxPRafferty%2Fj2efxw3L%2Fshow%2F%3Feditor_console%3Dfalse#%7B%22ci%22%3A0%2C%22os%22%3A1060.1450000031036%2C%22ls%22%3A890.9449999991921%2C%22le%22%3A894.1649999978836%7D" allowtransparency="true" allowFullScreen={true} frameBorder="0" height="829" data-instgrm-payload-id="instagram-media-payload-0" scrolling="no" style={{ "margin": "auto", "background": "white", "maxWidth": "540px", "width": "calc(100% - 2px)", "borderRadius": "3px", "border": "1px solid rgb(219, 219, 219)", "boxShadow": "none", "display": "block", "minWidth": "326px", "padding": "0px" }} title="GOBLIN WEEK"></iframe>
      </PostContainer>
    </Page>
  );
}

export default App;