import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
   drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
   drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

function Loremenu(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


   return (
    <div className="shadow-override" style={{
        position: "relative",
        
    }} >
      <CssBaseline />
    

   <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"

            style={{
              position:"absolute",
              left:"0",
              fontSize:"40px",
              color: "White",
            }}

          >
            <MenuIcon />

                &nbsp;&nbsp;LORE ZONE
    </IconButton>


      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
         <List>
         
          <ListItem button key={'Goblins obviously'}>
            <Link href="#/lore/" variant="body2">
             LORE HOME
            </Link>
          </ListItem>
          
            <ListItem button key={'Goblins obviously'}>
            <Link href="#/lore/Genniken" variant="body2">
             House Genniken
            </Link>
          </ListItem>  
       
           <ListItem button key={'Goblins obviously'}>
            <Link href="#/lore/Gytarrh" variant="body2">
            House Gytarrh
            </Link>
          </ListItem>  
        
           <ListItem button key={'Goblins obviously'}>
            <Link href="#/lore/Massadoon" variant="body2">
            House Massadoon
            </Link>
          </ListItem>   
       
          <ListItem button key={'Goblins obviously'}>
            <Link href="#/lore/Phouss" variant="body2">
            House Phouss
            </Link>
          </ListItem>   
        
          <ListItem button key={'Goblins obviously'}>
            <Link href="#/lore/Bolgh" variant="body2">
            House Bolgh
            </Link>
          </ListItem>   
       
          <ListItem button key={'Goblins obviously'}>
            <Link href="#/lore/malechia" variant="body2">
           House Malechia
            </Link>
          </ListItem>

   <ListItem button key={'Goblins obviously'}>
            <Link href="#/lore/codex-goblinarium" variant="body2">
             Codex Goblinarium
            </Link>
          </ListItem>


        
      </List>
        <Divider />
      </Drawer>
     
    </div>
  );
}
export default Loremenu;