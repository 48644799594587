import React from 'react';
import styled from 'styled-components';

const SlimBoi = styled.div`
    background-image: url(/slim.png);
    position: absolute;
    height: 180px;
    width: 100px;
    display: inline-block;
    background-size: contain;
`;

function useInterval(callback, delay) {
    const intervalId = React.useRef(null);
    const savedCallback = React.useRef(callback);
    React.useEffect(() => {
      savedCallback.current = callback;
    });
    React.useEffect(() => {
      const tick = () => savedCallback.current();
      if (typeof delay === 'number') {
        intervalId.current = window.setInterval(tick, delay);
        return () => window.clearInterval(intervalId.current);
      }
    }, [delay]);
    return intervalId.current;
  }

function Slim(props) {
    const startingX = props.x || 250;
    const startingY = props.y || 250;
    const [boiX, setBoiX] = React.useState(startingX);
    const [boiY, setBoiY] = React.useState(startingY);
    // const [boiMover, setBoiMover] = React.useState(window.setInterval(() => {


    // }, 100));
    useInterval(() => {
        const movementX = Math.random() * 10 - 5;
        const movementY = Math.random() * 10 - 5;
        setBoiX(boiX + movementX);
        setBoiY(boiY + movementY);
    }, 100);
    const whatDo = props.special || (() => window.location.hash = "/slimland");
  return (
        <SlimBoi boitop={boiY+"px"} boileft={boiX+"px"} style={{top: boiY+"px", left: boiX+"px"}} onClick={ whatDo }></SlimBoi>
  );
}

export default Slim;