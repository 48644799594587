import React from 'react';
//import styled from 'styled-components';

import Page from '../templates/lorepage';

import Slim from '../components/slim';


function PageBase() {
  return (
    <Page>
      <h1>Massadoon</h1>
      <h2>Third house of Mepho</h2>
      <p>Motto: “Oh, those Trumpets”, (emphasis on "oh" changes when said by others)</p>
      <p>Characteristics of Note: Screaming. Bigness, Terror, Trumpets. Dumbness. Air Pollution.</p>
      <p>Symbols: A dog. Shattered Mirrors.</p>
      <p>The character of the goblins of Massadoon can not be more clearly expressed than in their Trumpets. A common joke in the empire is that the massadoon are born with three horns: two of bone on their heads, and one of brass in their mouth. While a Massadoon herself might claim this refers simply to their innate love and talent for music, anyone who has spent any amount of time in a hive can recount stories of dropped pots and startled gasps following an enthusiastic blast from a Greathorn that some enterprising young Massadoon has dragged to the top of a building to celebrate this coming of age, of the dawn of a new day, or a particularly good meal. That sort of thing.
</p>
<p>This brings us to another great love of the massadon: parties. Be it the mountain-shakingly loud all-night raves famous in their ancestral homeland, of the orderly balls of the capitol, no right minded Massadoon will pass on the opportunity for a night of drink, dancing, and jockeying for social status. All manner of contest are on the table at a party; fights, footraces, dueling horns, regular dueling. If a winner can be decided in front of witnesses, it’s fair game. This also makes terrible gossips of them when not actively competing themselves, a trait which makes for skilled spies, detectives, and proud (if not vicious) members of the court Tun.</p>
<p>Their yen for status also has led them to clamor for work i the empires’ guilds, many of which they themselves founded. While never as a whole considered strong scholars or augers, their physical prowess and “go gettem” attitudes see many massadoon rise high in the ranks important guilds like “the dogges” of the city watch, or the “fell and ferocious” anti-orphan exterminators.</p>
<p>Physically, the Massadoon are most notable for their size, often more than twice the height of their countrymen. They also are a horned morph, and always have two large, bull-like horns, regardless of gender. They are sometimes furred, everywhere except their face, and most cannot grow beards or moustaches. Their hair is typically jet black, but exceptions of all kinds can be found. Their skin can be any color. Aside from their size and distinctive horns, Massadoon can be easily identified by their signature vacant stare. </p>
<p>Dwelling originally in the mountain range bordering the plains of Gytarrh, the Massadoon are a hearty folk, well-equipped for life at altitude. While they lack the wings of other houses (and generally distain flight) their long, sturdy builds make them excellent climbers. This propensity colors nearly every aspect of the culture of the massadoon, and is a frequent disturbance as they idly find themselves atop whatever is highest in a room.</p>
<p>The Massadoon have been the friends of the Gytarrh for as long as history, and beyond into legend. It is said that when Magnus forged the world from the slain, mangled corpse of his betrayed brother, he created two peoples, intending them to be brothers in arms as a tribute to his mistake. The history of the houses, and even before the founding of the empire, of the tribes shows this to be more or less true, at least with regard to the relationship between these peoples; They have never known war between one another, and where one can be found so can the other. Simply put, for whatever reaason the Gytarrh simply love the Massadoon, and the Massadoon never stopped to consider that that was an unusual arrangement.</p>
<p>A final, curious note on the Massadoon is the “curse” of their people. Reports of the origin of the curse are as varied as its effects, but all can agree on a few points. Firstly, there is the simple, wide acceptance that there is in fact a curse, regardless of its reason of nature. Second, the curse brings (amongst all manner of other possible maladies) voices. These voices may be malevolent, demanding violence or chaos, or helpful, whispering encouragement, emparting hidden or lost knowledge, or even muttering vagaries of things that were or things that have not yet come to pass. For the unlucky, their voices speak all three. This makes the massadoon susceptible to lunacy and occult influence, and these weaknesses, if not in truth then in stereotype, are effectively exploited by their rivals.</p>
      <Slim x={-100} y={-200}></Slim>
    </Page>
  );
}

export default PageBase;
