import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import { Formik } from 'formik';

const Body = styled.div`
    margin: 0px -40px;
    font-size: 35px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    a, span{
      flex: 1 1 33%;
      color: white;

    }
    a:link {
      color:white;
    }

    a:visited {
      color:white;
    }

 
    a:hover {
      color:yellow;
    }
    span{
      color: white;
    }

    background-color: green;

    .garbagemail:hover{
      color: yellow;
    }

`;

const MyTextarea = styled.textarea`
  min-width: 200px;
  min-height: 100px;
`;

const MyModal = styled(Modal)`
  color: white;
  background-color: rgba(0, 125, 0, 0.5);
  p {
    background-color: transparent;
  }
`;

const sendEmail = (values) => {
  window.Email.send({
    SecureToken: "6d8b9bca-393e-4170-a55c-7befdc304185",
    To: 'goblinarium-inquiries@maxrafferty.com',
    From: values.email,
    Subject: values.subject,
    Body: values.message
  }).then(
    message => console.log(message)
  );
}

const ModalWrapper = styled.div`
  text-align: center;
`;

const Footer = (props) => {
  const [mailOpen, setMailOpen] = React.useState(false);

  return (
    <Body >
      <a className='instagram' href='https://www.instagram.com/goblinariumnet' target="_blank" rel="noreferrer" >Instagram</a>
      <span>|</span>

      <a className='twitter' href='https://www.twitter.com/goblinarium_net' target="_blank" rel="noreferrer">Twitter</a>

      <span>|</span>

      <span className='garbagemail' style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => setMailOpen(true)}>Contact</span>
      {props.children}
      <MyModal
        open={mailOpen}
        onClose={() => setMailOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalWrapper>
          <Formik
            initialValues={{ email: '', subject: '', message: '' }}
            validate={values => {
              const errors = {};
              if (!values.subject) {
                errors.subject = 'Required';
              }
              if (!values.message) {
                errors.message = 'Required';
              }
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                sendEmail(values);
                setSubmitting(false);
                setMailOpen(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <p>
                  <b>Email:</b><br />
                  <input
                    type="email"
                    name="email"
                    placeholder="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && errors.email}
                </p>
                <p>
                  <b>Subject:</b><br />
                  <input
                    type="subject"
                    name="subject"
                    placeholder="subject"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subject}
                  />
                  {errors.subject && touched.subject && errors.subject}
                </p>
                <p>
                  <b>Message:</b><br />
                  <MyTextarea
                    type="message"
                    name="message"
                    placeholder="Message: Is there something youd like to see on The Goblinarium? What can I do for you?"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                  />
                  {errors.message && touched.message && errors.message}
                </p>
                <p>
                  <button type="submit" disabled={isSubmitting}>
                    Submit
           </button>
                </p>
              </form>
            )}
          </Formik>
        </ModalWrapper>
      </MyModal>
    </Body>
  );
}

export {
  Footer
};
