import React from 'react';
//import styled from 'styled-components';
import { useParams } from "react-router-dom";
import { DiscussionEmbed } from 'disqus-react';

import Page from '../templates/page';

import Slim from '../components/slim';
import getPage from '../endpoint.get-page';

function PageBase() {
    let { id } = useParams();

    const [post, setPost] = React.useState({});


    React.useEffect(() => {

        const fetchPage = async () => {
            const post = await getPage(id);
            setPost(post);
        }
        fetchPage();
    }, [id])

    return (
        <Page>
            <div dangerouslySetInnerHTML={{ __html: post }} />
            <Slim x={-100} y={-200}></Slim>
            <DiscussionEmbed
                shortname='goblinarium-net'
                config={
                    {
                        url: `https://goblinarium.net/posts/${id}`,
                        identifier: `goblinarium_post_${id}_0`,
                        title: `Goblinarium Post ${id}`,
                        language: 'en_US'
                    }
                }
            />
        </Page>
    );
}

export default PageBase;