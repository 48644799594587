import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import readIndex from './endpoint.read-index';

const getUriWithParam = (baseUrl, params) => {
  const Url = new URL(baseUrl);
  let urlParams = new URLSearchParams(Url.search);
  for (const key in params) {
    if (params[key] !== undefined) {
      urlParams.set(key, params[key]);
    }
  }
  Url.search = urlParams.toString();
  return Url.toString();
};

(async () => {
  const pages = await readIndex();

  console.log(JSON.stringify(pages))
  const urlParams = new URLSearchParams(window.location.search);
  let page = urlParams.get('page') == null ? 0 : +urlParams.get('page');
  //let post = urlParams.get('post');

  const uri = getUriWithParam(document.location.href, {'page': page});

  window.history.replaceState({}, "horka morka", [uri])

  /* @TODO: pull pages and display */

  ReactDOM.render(
    <React.StrictMode>
      <App postList={pages[page]} page={page} totalPages={pages.length} />
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

})();
