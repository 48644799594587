import React from 'react';
//import styled from 'styled-components';

import Page from '../templates/lorepage';

import Slim from '../components/slim';


function PageBase() {
    return (
        <Page>
            <h1>The Bolgh</h1>
            <h2>Fifth house of Mepho</h2>
            <p>Motto: “Knock, Answer”</p>
            <p>Mamma, these boys are SPOOKY.</p>
            <p>Symbols: Halloween Decorations</p>
            <p>One would be well advised to avoid playing dice with a Bolgh. The fifth house of Mephis are (often literally) shrouded in superstition and mystery. Although they subscribe to wildly divergent schools of beliefs, and will as likely as not backstab one another over subtle differences in doctrine, Bolgh are universally bound by, and to, their own personal sets of beliefs. They adhere strictly to these self-imposed rules and superstitions, at the peril of all parties involved, themselves included.
 The core of these belifes can be summarized quite simply, however; The Bolgh are collectors of secrets.</p>
            <p>These formal structures of belief have unsurprisingly led to the formation of the majority of institutions of faith throughout the Goblinarium, and its wide variety of cults and formal religious worship. This includes the official state cult to Magnus. Bolgh originated religious practices can be found in every corner of the empire, and top leaders of these faiths will more often than not be of house Bolgh. This is, of course, because typically only they understand the layers upon layers of secret signals, rituals, taboos, and passwords required to access the inner sanctums of their churches; They have constructed cults within their cults within cults, and the penalties for missteps in thes formal dealings are cruel at best. There are few pacifists in the keeping of hordefolk dogma.</p>
            <p>Secrets and secrecy are embedded deeply in the hearts of the members of house Bolgh. At their warmest and friendliest, they are sharing their secrets with a trusted confidant or friend. This forms the basis for how Bolgh social circles form; A loved one should be given the knowledge and rituals that allow them to best survive the world and grow. These secrets need not be dramatic; the mundane will be jealously hoarded right alongside the grave. A frail grandmother may whisper a delicious recipe for cookies to a favored grandchild, while giving her petulant brother only a tip for mixing mulch, simply to save face as she wishes the both of them goodbye. A date with a Bolgh that has gone well may end in a whispered confidence about where their feisty companion can next be found (no right minded Bolgh shares the location of their home with any freeness). A Bolgh, in general, never gives any information freely, and the veracity of anything given is always a matter of faith on the part of the listener.
</p>
            <p>For all of this intrigue, the daily life of a common Bolgh is typically quite ordinary. Being the descendants of highland ranchers and farmers, Bolgh tend to keep to themselves and their inner circle. They frequently long for simpler times, and are great lovers of nature and things they consider “natural”. This love manifests in many ways. Bolgh are avid gardeners, often filling their hives with all manner of flora (and fauna!) to help them mitigate what they consider the miseries of city life. They also tend to be tremendously good cooks, operating widely famed restaurants and preparing kingly meals for banquets and balls; an invitation to a home cooked Bolgh meal is not to be refused (nor accepted lightly!). Most Bolgh also have a strong preference for extreme light or dark, considering them to be the purest expressions of “natural order”. This tendency makes Bolgh natural architects and decorators, able to understand and manipulate spaces to maximize their preferred interplay of light and shadow.</p>
            <p>The interaction of their superstition and natural love can affect them in powerful, and sometimes comical, ways. Bolgh have a reputation for being charlatans, claiming to have found a method for speaking to the trees, or summoning long dead relatives, or other such nonsense. Readers of palms are common, as are tricksters and magicians, all with no more skill in augery than a housecat (known dampeners of ritual power, and beloved pets of the Bolgh). Bolgh youths are prone to wonton spiritualism as they calcify their internal codes, often drifting about seeking “connection with spirits” and generally being obnoxious and unproductive. For these reasons, more sensible Bolgh tend to be very private with their true talents in augery, and many who could have nurtured such skills are either discouraged or uninterested in such pursuits, and never develop. Public augery amongst the Bolgh is uncommon.</p>
            <p>Physically, Bolgh are a smaller breed, a touch taller than the Genniken, but of a heavier build. Being one of the “horned” morphs of hordefolk (along with the Massadoon), they are easily distinguished by a distinctive bony plate that develops on their foreheads as they come of age. This plate grows continuously through the life of the goblin, and terminates in antler-like prongs typically numbering between three and twelve points. These plates, left unchecked, can become truly enormous and unwieldy, and unshorn Bolgh plates have been known to be used as bucklers when looted by their hated Malken foes after battle. More frequently, however, these plates are cut, shaved, or pierced to suit the style and fashion of the hour. The Bolgh grow dark, thin, straight hair on their heads behind their plates, and can produce moustaches but not beards. They are otherwise largely hairless. Their skin tones are typically pale.</p>
            <p>In society, the house of Bolgh (when not acting in a religious capacity) tend towards jobs and functions that appeal to their innate talents for seeking information. They make good police as well as couriers, and can also be capable hunters and trappers. They excel as academic researchers of all varieties. Their knack for finding and following their own esoteric superstitious rules lets them easily navigate bureaucratic structures, though the often erratic behaviors stemming from these very same internal rules, paired with the general perception of Bolgh as “creepy”, often prevents their rise beyond middle management or lieutenants. On the topic of their “creepiness”, the Bolgh do not do themselves any favors. They have a penchant for black, and favor heavy robes, cloaks, capes, and cowls in their dress. Long, pointed hats are a timeless style. Further, some wishing to remain private or modest in public choose to wear “traveller’s masks”, sometimes simple white coverings, but just as frequently ornate ordeals stylized as skulls, or birds-heads. Veils, too, are common. Such macabre symbols, to a Bolgh, are thought of as bringing good fortune. Many view death and its associations as blessings. A lacking sense of fear around mortality is universal amongst them.</p>
            <p>This lack of concern around death can make dealings with unknown Bolgh very dangerous. They have been known to turn murderous at the drop of a hat, should a particularly offensive breach of their internal, secret customs occur. In the presence of a group of close Bolgh family or friends who have been sharing one another's secrets for “keeping the natural order” for years, an uncautious word or an ominous move in a game can lead to an otherwise friendly outsider being suddenly torn to shreds to appease the spirits of the house. This lack of compunction against murder also makes of the Bolgh common thieves, outlaws, and assassins, and just as frequently leads them to membership of “The Fell and Ferocious” guild, tasked with the elimination of dangerous wild infestations of primal folk and chitterlings that plauge the outskirts of all hordefolk communities of any real size. Likewise, they have no fear of their own death, and are thusly inclined to work with dangerous chemicals and explosives. Their grenados are the stuff of legend.</p>
            <p>A final warning: Bolgh remain vital long into their twilight years. One of the oldest-living morphs of hordefolk, the orneriness of their elders is legendary; “Baba Bolgh”, the matron of the house in folklore, is said to have dragged princelings about by their ears, and to have openly beaten kings on their thrones with a broom. It’s told that she eventually grew so old, and so enormous, that she simply sat down one day and became Mount Hate, the ever-raging volcano in the heart of the ancestral Bolgh homeland. In summary, <b>do not cross little old Bolgh ladies.</b></p>
            <Slim x={-100} y={-200}></Slim>
        </Page>
    );
}

export default PageBase;