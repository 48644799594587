import React from 'react';
//import styled from 'styled-components';

import Page from '../templates/lorepage';

import Slim from '../components/slim';


function PageBase() {
  return (
    <Page>
      <h1>The Phouss</h1>
      <h2>Fouth house of Mepho</h2>
      <p>Motto: “Blight’s Fruit”</p>
      <p>Symbols: A Rose. An interdimensional portal fueled by violence.</p>
      <p>The Fourth Family of Mephistus is characterized by conflict. Originating from the same ancestral tribe as the Ramblynge, the first house of Malken, the Phouss’ ancient betrayal of their Malken countrymen to support the rising house of Mepho cemented their place in the empire, and in the eyes of history; Even amongst their allied houses, Phoussi battle against stereotypes of unreliability, discordancy, and even outright treachery. In general, the Pouss could not resemble these characteristics less; they are fiercely patriotic (often to a fault), loyal to tradition, and committed to giving their all to whatever cause their lives have drawn them to. However, when they fail in their goals, or succumb to society's expectations of them, Phoussi can make nightmarish rogues, villains, and vagabonds.</p>
      <p>Phouss are physically differentiated from their Rhamblynge cousins in two main ways: their ears, and their antennae. Phouss noses stop growing when they come of age and are generally on the shorter side of all goblin facial extremeties. Their ears, if they have them at all, are typically small and only mildly pointed, and all Phouss are technically deaf. However, the House of Phouss is blessed with sensory glands mounted on antennae slung from their foreheads back across their scalps. These organs are sensitive to a variety of inputs and signals (including air pressure, allowing them to “hear”), and also grant a vague emotional sensitivity. This brings communities of Phouss very close, but can also isolate them to individuals not aware of their empathetic connections that they share. Exacerbating this, Phouss communities tend to speak a (highly divergent) form of the Rham language amongst themselves.</p>
      <p>Stereotypes and insular communities aside, many Phoussi are held in high esteem throughout the empire, distinguishing themselves as mighty heroes, venerated saints, and (more often than not) Queens. The Phouss are considered by many to be the most beautiful race of hordefolk, and as such are highly coveted partners at all levels of society. This is true even in Malken and amongst the unbroken tribes, where captured Phouss are precious booty indeed, and whose offspring frequently breed true for generations amongst the enemies of the empire. Though not as tall as their Massadoon peers by a head or two, the Phouss are generally lean and tall. Though they rarely put on much in the way of either fat or muscle, they are often gifted sprinters, riders, dualists, and archers, and these talents are not wasted by the armies of Mepho.</p>
      <p>In Social life, Phouss tend towards the dramatic. Their love of extravagance and finery lead them to rival the Massadoon in a love for parties and balls, and while their connection to music is less primal, Phouss bards, poets, and artists of all varieties are esteemed far and wide. Indeed, it would be difficult to find a Phouss without some sort of (often extremely) esoteric artistic pursuit. It is widely known truism that it is a danger to offer a Phouss a stage, but a true catastrophe to try to keep one from one. True enough, they are prone to spectacle, and delight in making a dramatic entrance. Given the right circumstances, they will always “lay it on thick”. To make matters worse, all Phoussi possess some level of innate augury; they tend to use it to apparate roses at a key moment in a speech, or to explode out of a cloud of rose petals into a room. The Rose of Phouss, their house emblem, is thusly, aggravatingly, well chosen. </p>
      <p>A quirk of the Phouss bloodline is their curious personal motivations, and their explanations thereon. Though they consider themselves strictly traditional and conservative, their individual behavior is often both erratic and at odds with many of their own stated practices. Point this out to a Phouss at one's own peril! Their capacity to twist reason to square their behavior and their beliefs often boggles the mind. It is not that they are deluded or liars. It would be closer to say that the universal laws of logic and truth are simply different for the Phouss, as their conclusions, though baffling, are consistent and consistently applied. This behavior, if understood carefully, can make the Phouss susceptible to manipulation, and a well-worded contract can ensnare an unwitting or unlucky Phouss into a trap of their own making. If nothing else, Phoussi can be relied upon strongly to behave precisely as themselves and no one else.</p>
      <p>Lastly, though considered by most to be at best a campfire legend, and at worst pure folly, a treatise on the Phouss is not complete without a mention of the spasmatic warp. It is said that a Phouss who engages in an act of Pure Violence, penetrating flesh, muscle, mind, and soul, will open a rift obliterating their physical form and becoming a spasmatic warp beast, an entity of pure energy appearing as a blurred mass of arms, claws, teeth, and gore. Such creatures, if they can even so be called, are said to be capable of laying waste to legions, even whole kingdoms. Old tales tell of Legendary Warriors who could enter the state at will, convulsing violently before the start of a battle, and awakening weeks later continents away with no memory and only a swath of destruction to lead them home. Modern accounts, though few and universally unverified, never report the return of the afflicted; The opening of such a portal is now commonly considered a terminal curse, to be avoided at all costs. As such, cults of pacifism are common in the Empire, often populated by Phouss superstitiously attempting to avoid such a fate. However, such cults are now commonly attended by hordefolk of all houses  seeking answers to a violent, confusing world.</p>
      <Slim x={-100} y={-200}></Slim>
    </Page>
  );
}

export default PageBase;
