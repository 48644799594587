import React from 'react';
//import styled from 'styled-components';

import Page from '../templates/lorepage';

import Slim from '../components/slim';


function PageBase() {
  return (
    <Page>
      <h1>Malechia</h1>
      <h2>Sixth House of Mepho</h2>
      <p>Motto: “Tooth and Bone”</p>
      <p>Symbols: A tooth and a bone.</p>
      <p>A conquered people, only in recent decades have the Malechia truly began to be accepted as, and accept their own identity as the Sixth House of Mephostus. Taken in the most recent crusade against the Malken Concordant, the fertile valleys of Malechia are in fact the namesake of the confederacy itself, “The kin of Malec”, referring to an ancient king of legend, whom all Malken claim to be the progenitor of all modern hordefolk. It was in Malec’s ancient castle, located in on the highest peak in Malechia, where the vows and writs of the concordant were signed. Conquered as much for the valley’s enormous potential for agricultural production, as to simply wound the pride of Malken by holding this landmark, the occupation of Malechia is a constant thorn in the side of the Malechians former Malken allies. The history of the Malechian people is deeply entwined with the peoples of both Mephostus and Malken. These histories are as embellished as they are long.</p>
      <p>The geography of Malechia has also had a profound impact on the development of the peoples living within. The former nation itself is long and narrow, following the course of the Catsface River, and is capped on its southern border by Lake Catsface. The river has carved deep valleys into the mountain range running this same course, and the river itself originates somewhere in the mountainous expanses to the north, eventually bordered by southern badlands of the Malken Beldrin nation. To its west lies Mephistus, and to its east further Malken lands.The deep, wide valleys are densely forested by ancient Black Ironwoods, their dense canopies housing all manner of bizarre and unique life. Perpetually misty, temperate, and shielded from weather by the surrounding peaks, these valleys are a veritable nursery for the Primal hordefolk. When travelling, one must be vigilant for signs that one is entering the hunting grounds of a mysterious and savage Lost Goblin, or one may be awed by the extensive “decoration” of a section of woods claimed by one of the Fallow Folk. The sound of wild, youthful Goblin Chitterlings echo continuously through the trees in Malechia, and many an errant traveller has found themselves grown accustomed to the sound only to be ambushed, shredded, and eaten by a colony of wily, deadly youngsters lying in wait. In such an environment, these creatures multiply by the score. Much to the consternation of the Mephistan invaders, these are hordefolk driven by their most ancient instincts; They can no more be removed from the valley as could the mountains be removed from the Goblinarium. It is in this setting that, inconceivably to the “civilized” Mephistans, that the Malechian tribes made their home.</p>
      <p>Malechian villages tended to crop up in the various clearings that appear throughout the dense woods. The places are very stable, expanding naturally with the river and the woods. The tribespeople live in harmony with the forest. Friendliness and openness run to the very core of Malechians, and this trait has enabled them to foster relationships with nature; Malechian tribes remain as much a part of the nature of the valley as their primal neighbors. This bond with nature (to the endless fascination of their Bolgh countrymen) allows them a degree of inherent communication with all freeholder hordefolk (not all primal goblins are savages), a skill unknown in the rest of the modern goblin kingdoms. To this end, the naitive Malechians have cultivated their local Lost and Fallow, helping to establish stable hunting areas and regions of protection and safe passage, frequently going as far as refusing to fell trees or repair their own damaged buildings without first consulting what most other hordefolk would consider the local wildlife.</p>
      <p>This kinship with the lesser goblin morphs has also colored their modern lives. Often living on the fringes of urban life, they frequently form bonds with the urban primals that appear universally at the edges of hives and cities, anywhere that maintenance has lapsed for a period of time. These communities can, left alone, form into semi-stable shanty towns and then slums. The Malechians in these communities then become their translators, go-betweens, and general allies of these massively marginalized groups. When given the opportunity, Malechians make empathetic police in the best of cases, and masterful manipulators in the worst. Their familiarity with handling Chitterlings and their particular brand of chaos and mischief make them teachers held in the highest regard, and their talents grooming wild things make them natural gardeners and, of course, farmers. This, paired with their ungoblinlike ambivalence to court struggles and political vying for status, has lead to a wide diaspora of Malechians striking out from the “safety” of the hives into the Mephostan wilds, enterprisingly seeking steadholds to cultivate. The clever, the scrappy, and the lucky succeed; Most are not so fortunate. The hinterlands are truly wild.</p>
      <p>This brings us to an uncomfortable topic in the empire -- its indentured. While outright slavery is uncommon amongst goblins (hordefolk are nearly impossible to bind in any capacity), modern goblin nations have found debt to be an effective means of controlling populations. This plays against many elements core to the majority of hordefolk, their greed, their love of wealth (by whatever measure), their lust for status and fame. All of these, paired with the advent of international goblin banks, prevent wily goblins from wriggling free of or raging against these particular fetters. As such, most goblin laws, including murder and high-crimes, are enforced by fines rather than enprisonment, as the jailing is also effectively impossible for groups of any real size. Those unable to pay are bound to their debtors, and their debts are paid down at strictly enforced standard rates based on the skills of the individual, and the nature of their “employment”. Debts are inherited as well, leading to awkwardness at best, and more often outright abuse; This generational sharing of the burden leads to stratification of social class, and many strange arrangements around adoptions and child rearing at the depths of the social ladder. Having few official channels of recourse, these debts can and have lingered for generations.
</p>
<p>No single group has been more affected by these policies than the Malechians, as following the surrender of their lands, their people were required to pay reparations for the costs of the war, typically in the form of life debts for the active participants. Considered by many even inside the empire as a systematic injustice, none spoke loudly enough to shield the Malechians from this fate. In the aftermath of war, the conquered found few allies amongst their new peers. Though as each decade has passed more and more of the Malechians penetrate every level of the empire, their historic plight has conditioned Malechian families with the experience of skilled servants of all kinds, many making excellent scribes, butlers, tailors, waitresses, and bureaucrats.</p>
<p>On the other end of this spectrum, many Malechia have chosen to act against their trusting nature and find themselves radicalized against their oppressors. They utilize their various other talents (perhaps none more important than all goblins tendency towards a pack mentality), to stir the pot wherever they can. Malechian riots are common, and they are frequently found to be at the heart of oppositional ground-level groups, making good ring-leaders, labor organizers, and conspiracy theorists. The empathy of a clever Malechian itself is a potent weapon, frequently convincing hordefolk of all shapes to work together towards a common goal; This makes of the few enlisted Malechians quick and effective captains, and also highlights the unfortunate reality of the ever-present risks posed by Malechian separatists, terrorists, and revolutionary cells.
</p>
<p>While modern Malechians face many struggles, all is not suffering for them in the empire.  Following the re-unification of their various established tribes within the empire, they became strong enough as a bloc to be able to put fourth a primach and formally found the House of Malech. The Man selected as their Prime remains the greatest hero in the modern era, Goblin George Washington, a figure of terror and legend spoken across campfires and in children's stories. By all accounts, this guy was just the absolute fuckin tits. A triumphant Mephistan general by any standard, he inspired hope in the future for his people. The day of his passing, nearly a century passed, is a national holiday celebrated by all, and is attended fervently by all Malechians, treated by them as a day of general mourning for all their dead.</p>
<p>Their most important contribution to the modern empire is their mechanics. Their love for the natural extends to academics as well, and they have excelled at unlocking many secrets of Physicks, Alchemy, and various other natural sciences. The fruits of these labors are most violently represented by the development of brutal explosives in collaboration with the Corp of Bolgh Grenadiers, who identified quickly the applications of these natural discoveries. In relevance, just shy of comically destructive bombs, are the Malechia’s own war engines, capable of their own varieties of destruction. These developments have wrought havoc upon the enemies of the empire. Domestically, they have developed many new building materials in conjunction with the Gytarrh and Massadoon dominated Guild of Builders, many times stronger than previous practices. With the Bolgh guild of Architects, they have built the hives to proportions so epic and awesome that they rival mountains.</p>
<p>Physically, though their traits vary somewhat based upon their tribe of origin, Malechians resemble the Gytarrh in many ways, being of medium height and weight, if more often than not on the greener side. Their skin tones are considered by many to be the goblin ideal, ranging from a dark, hunter green to a pale olive (Gytarrh can sometimes be identified by being at the extremes of these ranges, sometimes being pale enough to be described as white or tan, or dark enough to appear deep brown or black.). Like the Genniken, their ears and noses continue to grow throughout their life, though unlike the Genniken no other extremities continue to grow, and they do not consider long features to be symbols of status, and have been known to trim these features to better blend with the more common of their Gytarrh countrymen. The most distinctive feature of the Malechians is that they are the only true “fanged” morph in the empire (though the Massadoon occasionally develop one or more protruding fangs, and the Lla’amosa and Aggut of Malken run true to this characteristic), having two prominet fangs that protrude up beyond their upper lip from their lower jaw. These fangs are deadly and strong, and are to be avoided at all costs in an all-out scrap with a Malechian. Many favor veils, masks, or scarves to hide these distinctive features, and in extreme cases they will file or remove these fangs for their own privacy.</p>
<p>Because of their ability to pass amongst many other morphs, many consider the children of Malech to be extremely “average” looking gobins. As a whole, they are considered to be a plain, but not ungainly, morph, such that when many imagine the “generic” goblin, as one would draw as the little figure that you put on a bathroom to indicate mens/womens, the result will appear to perhaps be Malechian. Their proportions are not dissimilar to those of chitterlings scaled up, and their connections to the natural world imply an essential “goblinhood” that many have taken to heart as its own measure of beauty. For all these reasons, should a Phouss not be available or desirable for a particular job, Malechians are frequently employed as models, actors, and mannequins. Further, because of these associations, they have of late developed something of a reputation as fashionistas and dandies. They have a habit of decorating themselves with tribal tattoos, and are much less wary than any other goblin of “splicing” parts from even non-goblin hordefolk to their bodies for aesthetic purposes. This can take the form of mushrooms growing from their scalps in place of hair, or replacing their fingers and toes with a trolls to appear more like the Genniken. Common amongst the “Heavies” of the legions, fetishes of fingers or scalps are collected from felled foes and spliced all over their bodies to acquire their strength. Overall, these preferences are frequently traditional to the tribe from which they descend, though post tribal reunification this is far from universal. Many do so for purely aesthetic reasons.</p>
<p>A final note on the old Malechian tribes: nearly all Malechians are at least aware of their original tribe from the Malechian lands. Many hold it as a core part of their identity. Malechian neighborhoods and social groups are organized around these ties, and ancient friendships and rivalries (generally based on physically geography in the Malechian valley) remain. There are hundreds of individual tribal identities, with their own unique customs, cultures, and traditions. Because of the Malechian’s welcoming nature, any hordefolk who shows an affinity for these tribal practices, regardless of house or morph, will be welcomed with open arms by the modern tribal groups to learn and participate. The result is that many non-exclusive social clubs and circles of friends are centered around Malechian tribal affiliations. From this, has grown a multitude of local sports clubs and mascots centered around these clans, to the general delight of the members of the represented tribes. The contests between these clubs rapidly approach an official status as the pasttime of the empire.</p>
      <Slim x={-100} y={-200}></Slim>
    </Page>
  );
}

export default PageBase;