import React from 'react';
//import styled from 'styled-components';

import Page from '../templates/lorepage';

import Slim from '../components/slim';


function PageBase() {
  return (
    <Page>
     
      <h1>The Tooting of a Horn</h1>
      <p>Pathapple looked at the cloudy sky and waited for his time. The clouds slowly clipped forward, and the moonlight was subsiding. Path took a deep breath and closed his eyes, drawing the musty underhive fog into his nostrils. Drawing up, he straightened his back, and the first light of dawn struck his face. A little old lady in a witchy robe glared at the shiny horn in his hands as she shuffled home with her groceries. It was time.</p>
      <p>He grabbed handhold after handhold of rotten brick in the old wizard tower. He had heard that an eccentric was now running the place as a library. Loony, Path thought. No good in books. But such was the way of the Bolgh and their secrets. No, Pathapple needed only fresh air to fill his lungs, and a fine buglehorn to empty them. Oh, and a height, from which to blast. And A population to hear it. On he climbed.</p>
      <p>Below, the city began to awaken. The sun clicked across the horizon, its warm beams stirring the various creatures interred within to their daily action. A pigeon dove for a breadcrumb, and a hawk swiftly caught it. A rat, still fat with yesterdays spoils, greedily pounced along the windowsills. A merchant coughed in his window as he flopped his “open” sign to its active position, awaiting the flurry of morning shoppers preparing to sail in his direction. Path saw all of this bemusedly, as he climbed higher, ever higher, on the tower.</p>
      <p>After what seemed a long while, though in reality Pathapple was a strong climber after his clan, he reached the towers peak. With care he slinked his long, strong fingers over the lip of the roof, and with caution that is only learned from a youthtime of brutal falls, pulled himself up by the strongest of tiles. A few slipped, and he hoped they would miss the tired morning bustle below. He looked down at the sprawling city before him, its mounds and neighborhoods stretching to the horizon in all directions. He looked up at the clockwork sky, and saw that he was just in time.</p>
      <p>The sun clicked forward, with an inaudible but mighty vibration indicating four oclock. All was as it should be, and Pathapple leaned out, clutching the spire of the tower in one hand, and swinging his enormous horn out over the ledge. He braced his legs against the sloped roof of the wizards tower, and drew in a breath. Today, the honor of the morning was his. Today, his voice would echo through every alley of Mephos. He loosed his blast, brassy and large, and heard its echo in the shattering of glass and the cursing of late sleepers as it spread out into the world, and their sleep knew his terrible message; Awake, Goblinarium!</p>
      <Slim x={-100} y={-200}></Slim>
    </Page>
  );
}

export default PageBase;