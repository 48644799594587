
async function readIndex() {
    const response = await fetch( "/data/index");
    const body = await response.text();

    // Line Delimited Page Index
    let pages = body.split(/\r?\n/);
    pages = pages.map((v) => {
        const posts = v.split(',');
        return posts.map(v => v.trim());
    });

    return pages;
};

export default readIndex;